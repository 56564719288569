import { changeSection, removeEventListenersScrollSectionBySection, eventListenersScrollSectionBySection } from './../scroll/sectionBySection'
import { clickOnView } from './clickOnView'
const arrowDown = document.querySelector('.arrow-down')
const arrowUp = document.querySelector('.arrow-up')
const viewContainer = document.querySelector('.viewContainer')
const sections = document.querySelectorAll('.sectionContainer section')
const infoNavbar = document.querySelector('.infoNavbar')
const detailsIcon = document.querySelector('.details-icon')
const views = document.querySelectorAll('.view')
const sectionContainer = document.querySelector('.sectionContainer')
const arrows = document.querySelectorAll('.arrowsContainer svg')
const infoContainer = document.querySelector('.infoContainer')
const sectionsText = document.querySelectorAll('.infoContainer .changeSectionText')

export const nextSection = async () => {
  removeEventListenersNavigationBar()
  removeEventListenersScrollSectionBySection()

  viewContainer.style.pointerEvents = 'none'
  let activeElementIndex = 0
  sections.forEach((section, index) => {
    if (section.classList.contains('active')) {
      activeElementIndex = index
    }
  })
  if (activeElementIndex == sections.length - 1 || activeElementIndex === undefined) {
    viewContainer.style.pointerEvents = 'initial'
  } else {
    await changeSection('next', activeElementIndex)
  }
  setTimeout(() => {
    if(infoContainer.classList.contains('open')) {
      eventListenersNavigationBar()
    }
    else {
      eventListenersNavigationBar()
      eventListenersScrollSectionBySection()
    }
  }, 1000)
}

export const previousSection = async () => {
  removeEventListenersNavigationBar()
  removeEventListenersScrollSectionBySection()
  viewContainer.style.pointerEvents = 'none'
  let activeElementIndex = 0
  sections.forEach((section, index) => {
    if (section.classList.contains('active')) {
      activeElementIndex = index
    }
  })
  if (activeElementIndex == 0 || activeElementIndex === undefined) {
    viewContainer.style.pointerEvents = 'initial'
  } else {
    await changeSection('previous', activeElementIndex)
  }
  setTimeout(() => {
    if(infoContainer.classList.contains('open')) {
      eventListenersNavigationBar()
    }
    else {
      eventListenersNavigationBar()
      eventListenersScrollSectionBySection()
    }
  }, 1000)
}

export const detailsIconInView = () => {
  detailsIcon.removeEventListener('click', detailsIconInView)
  let activeSection = document.querySelector('.sectionContainer section.active')
  let elements = document.querySelectorAll('.sectionContainer section.active > div')
  let part = activeSection.querySelector('.part')
  viewContainer.classList.add('transition')
  setTimeout(() => {
    elements.forEach(element => (element.style.display = 'none'))
    viewContainer.classList.remove('transition')
  }, 500)
  setTimeout(() => {
    views.forEach(view => {
      view.classList.remove('clickView')
      view.classList.add('onView')
    })
    infoNavbar.classList.remove('inView')
  }, 1500)
  setTimeout(() => {
    views.forEach(view => {
      view.classList.remove('onView')
      view.addEventListener('click', clickOnView)
    })
    arrows.forEach(element => element.classList.remove('hide'))
    sectionsText.forEach(element => element.classList.remove('hide'))
    part.classList.remove('resetTransform')
    elements.forEach(element => (element.style.display = 'flex'))
    viewContainer.style.pointerEvents = 'initial'
    sectionContainer.style.pointerEvents = 'none'
    activeSection.style.pointerEvents = 'none'
    eventListenersNavigationBar()
    eventListenersScrollSectionBySection()
  }, 2500)
}

export const detailsIconOutView = () => {
  if(infoContainer.classList.contains('open')) {
    infoContainer.classList.remove('open')
    eventListenersScrollSectionBySection()
    viewContainer.style.pointerEvents = 'initial'
  }
  else {
    infoContainer.classList.add('open')
    removeEventListenersScrollSectionBySection()
    viewContainer.style.pointerEvents = 'none'
  }
}

export const eventListenersNavigationBar = () => {
  arrowDown.addEventListener('click', nextSection)
  arrowUp.addEventListener('click', previousSection)

  detailsIcon.addEventListener('click', detailsIconOutView)
}

export const removeEventListenersNavigationBar = () => {
  arrowDown.removeEventListener('click', nextSection)
  arrowUp.removeEventListener('click', previousSection)

  detailsIcon.removeEventListener('click', detailsIconOutView)
}
