import 'regenerator-runtime/runtime'
import { eventListenersScrollSectionBySection } from './events/scroll/sectionBySection'
import { eventListenersViews } from './views'
import { eventListenerClickNextButton } from './events/click/nextButton'
import { eventListenersNavigationBar } from './events/click/navigationBar'
import { horizontalScroll } from './events/scroll/horizontalScroll'

eventListenersScrollSectionBySection()
eventListenersViews()
eventListenerClickNextButton()
eventListenersNavigationBar()

window.addEventListener('resize', () => {
  let activeSection = document.querySelector('.sectionContainer section.active')
  if (window.innerWidth > 850) {
    activeSection.addEventListener('wheel', horizontalScroll)
  } else {
    activeSection.removeEventListener('wheel', horizontalScroll)
  }
})
