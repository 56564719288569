import { scrollSectionBySection } from '../scroll/sectionBySection'
import { horizontalScroll } from '../scroll/horizontalScroll'
import { removeEventListenersNavigationBar, detailsIconInView } from './navigationBar'

const html = document.querySelector('html')
const body = document.querySelector('body')
const sectionContainer = document.querySelector('.sectionContainer')
const viewContainer = document.querySelector('.viewContainer')
const views = document.querySelectorAll('.view')
const sectionsText = document.querySelectorAll('.infoContainer .changeSectionText')
const arrows = document.querySelectorAll('.arrowsContainer svg')
const infoNavbar = document.querySelector('.infoNavbar')
const detailsIcon = document.querySelector('.details-icon')

export const clickOnView = () => {
  let activeSection = document.querySelector('.sectionContainer section.active')
  let title = activeSection.querySelector('.title h2')
  let part = activeSection.querySelector('.part')
  window.removeEventListener('wheel', scrollSectionBySection)
  window.removeEventListener('touchmove', scrollSectionBySection)
  removeEventListenersNavigationBar()
  views.forEach(view => (view.style.transform = 'rotate(-45deg) scale(1.2)'))
  sectionsText.forEach(element => element.classList.add('hide'))
  arrows.forEach(element => element.classList.add('hide'))
  infoNavbar.classList.add('inView')
  body.style.overflow = 'hidden'
  views.forEach(view => view.removeEventListener('click', clickOnView))
  setTimeout(() => {
    title.classList.add('textAppear')
    part.classList.add('resetTransform')
    views.forEach(view => (view.style.transform = null))
    views.forEach(view => view.classList.add('clickView'))
    if (window.innerWidth > 850) {
      activeSection.addEventListener('wheel', horizontalScroll)
    }
  }, 500)

  setTimeout(() => {
    activeSection.style.pointerEvents = 'initial'
    body.style.overflow = 'initial'
    html.style.overflow = 'initial'
    viewContainer.style.pointerEvents = 'none'
    sectionContainer.style.pointerEvents = 'initial'
    detailsIcon.addEventListener('click', detailsIconInView)
  }, 1000)
}
