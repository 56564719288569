import { touchMove, touchStart } from '../../utils/touchDelta'
import data from './../../../data.js'
import { removeEventListenersNavigationBar, eventListenersNavigationBar } from '../click/navigationBar'

const sectionContainer = document.querySelector('.sectionContainer')
const sections = sectionContainer.querySelectorAll('section')
const views = document.querySelectorAll('.view')
const sectionsText = document.querySelectorAll('.changeSectionText')
const viewContainer = document.querySelector('.viewContainer')
const arrowDown = document.querySelector('.arrow-down')
const arrowUp = document.querySelector('.arrow-up')

let timeout
let sectionContainerTranslateY = 0

export const changeSectionText = async index => {
  const section = data.find(element => element.id === index)
  sectionsText.forEach(text => {
    text.classList.add('changeText')
    if (text.dataset.name) {
      setTimeout(() => {
        if (Array.isArray(section[text.dataset.name])) {
          let result = ''
          section[text.dataset.name].forEach(element => {
            result += `<li>${element}</li>`
          })
          text.innerHTML = result
        } else {
          text.innerHTML = section[text.dataset.name]
        }
      }, 500)
    }
    setTimeout(() => {
      text.classList.remove('changeText')
    }, 1000)
  })
}

export const changeSection = async (direction, activeElementIndex) => {
  if (direction == 'next') {
    await changeSectionText(activeElementIndex + 1)
    sections[activeElementIndex + 1].classList.add('active')
    sections[activeElementIndex].classList.remove('active')
    sectionContainerTranslateY -= document.querySelector('.sectionContainer section').getBoundingClientRect().height
    sectionContainer.style.transform = `translateY(${sectionContainerTranslateY}px)`
    if(activeElementIndex+1 == sections.length - 1) {
      arrowDown.classList.add('disabled')
    } else {
      arrowUp.classList.remove('disabled')
    }
  } else if (direction == 'previous') {
    await changeSectionText(activeElementIndex - 1)
    sections[activeElementIndex - 1].classList.add('active')
    sections[activeElementIndex].classList.remove('active')
    sectionContainerTranslateY += document.querySelector('.sectionContainer section').getBoundingClientRect().height
    sectionContainer.style.transform = `translateY(${sectionContainerTranslateY}px)`
    if(activeElementIndex-1 <= 0) {
      arrowUp.classList.add('disabled')
    } else {
      arrowDown.classList.remove('disabled')
    }
  }

  views.forEach(view => view.classList.add('reset'))
  setTimeout(() => {
    views.forEach(view => view.classList.remove('reset'))
  }, 500)
  setTimeout(() => {
    sectionContainer.style.pointerEvents = 'none'
    viewContainer.style.pointerEvents = 'initial'
  }, 1000)
}
export const scrollSectionBySection = async event => {
  let deltaY = event.wheelDeltaY || -touchMove(event)
  if (deltaY == 0) return
  window.removeEventListener('wheel', scrollSectionBySection)
  removeEventListenersNavigationBar()
  sectionContainer.style.pointerEvents = 'initial'
  views.forEach(view => {
    view.classList.remove('onView')
  })
  clearTimeout(timeout)
  timeout = setTimeout(async () => {
    views.forEach(view => {
      view.classList.remove('onView')
    })
    let activeElementIndex = 0
    sections.forEach((section, index) => {
      if (section.classList.contains('active')) {
        activeElementIndex = index
      }
    })

    if (deltaY > 0) {
      if (activeElementIndex == 0) {
        sectionContainer.style.pointerEvents = 'none'
      } else {
        await changeSection('previous', activeElementIndex)
      }
    } else if (deltaY < 0) {
      if (activeElementIndex == sections.length - 1) {
        sectionContainer.style.pointerEvents = 'none'
      } else {
        await changeSection('next', activeElementIndex)
      }
    }
    setTimeout(() => {
      window.addEventListener('wheel', scrollSectionBySection)
      eventListenersNavigationBar()
    }, 1000)
  }, 500)
}

export const eventListenersScrollSectionBySection = () => {
  window.addEventListener('wheel', scrollSectionBySection)

  window.addEventListener('touchstart', touchStart)
  window.addEventListener('touchmove', scrollSectionBySection)
}

export const removeEventListenersScrollSectionBySection = () => {
  window.removeEventListener('wheel', scrollSectionBySection)

  window.removeEventListener('touchstart', touchStart)
  window.removeEventListener('touchmove', scrollSectionBySection)
}
