import { changeSection } from './../scroll/sectionBySection'
import { horizontalScroll } from './../scroll/horizontalScroll'
import { clickOnView } from './clickOnView'
import { scrollSectionBySection } from '../scroll/sectionBySection'
import { detailsIconInView, eventListenersNavigationBar } from './navigationBar'

const nextButtons = document.querySelectorAll('.next')
const sectionContainer = document.querySelector('.sectionContainer')
const sections = sectionContainer.querySelectorAll('section')
const viewContainer = document.querySelector('.viewContainer')
const views = document.querySelectorAll('.view')
const body = document.querySelector('body')
const lastSection = sectionContainer.lastElementChild
const sectionsText = document.querySelectorAll('.changeSectionText')
const detailsIcon = document.querySelector('.details-icon')
const arrows = document.querySelectorAll('.arrowsContainer svg')
const infoNavbar = document.querySelector('.infoNavbar')

export const eventListenerClickNextButton = () => {
  nextButtons.forEach(nextButton => {
    nextButton.addEventListener('click', () => {
      let elements = document.querySelectorAll('.sectionContainer section.active > div')
      let activeSection = document.querySelector('.sectionContainer section.active')
      let title = activeSection.querySelector('.title h2')
      let part = activeSection.querySelector('.part')
      title.classList.remove('textAppear')
      part.classList.remove('resetTransform')
      detailsIcon.removeEventListener('click', detailsIconInView)
      window.removeEventListener('scroll', horizontalScroll)
      body.style.overflow = 'hidden'
      sectionContainer.style.pointerEvents = 'none'
      viewContainer.classList.add('transition')
      activeSection.style.pointerEvents = 'none'
      setTimeout(() => {
        elements.forEach(element => (element.style.display = 'none'))
        viewContainer.classList.remove('transition')
      }, 500)

      setTimeout(() => {
        views.forEach(view => {
          view.classList.remove('clickView')
          view.classList.add('onView')
        })
      }, 1500)

      setTimeout(() => {
        views.forEach(view => {
          view.classList.remove('onView')
        })
        sectionsText.forEach(element => element.classList.remove('hide'))
        elements.forEach(element => (element.style.display = 'flex'))
      }, 2500)

      if (lastSection.classList.contains('active')) {
        setTimeout(() => {
          infoNavbar.classList.remove('inView')
        }, 1500)
        setTimeout(() => {
          window.addEventListener('wheel', scrollSectionBySection)
          window.addEventListener('touchmove', scrollSectionBySection)
          eventListenersNavigationBar()
          viewContainer.style.pointerEvents = 'initial'
          views.forEach(view => view.addEventListener('click', clickOnView))
          arrows.forEach(element => element.classList.remove('hide'))
        }, 2500)
        return
      }

      setTimeout(() => {
        viewContainer.style.pointerEvents = 'none'
        let activeElementIndex = 0
        sections.forEach((section, index) => {
          if (section.classList.contains('active')) {
            activeElementIndex = index
          }
        })
        changeSection('next', activeElementIndex)
      }, 3500)

      setTimeout(() => {
        views.forEach(view => {
          view.classList.add('onView')
        })
      }, 4500)

      setTimeout(() => {
        clickOnView()
      }, 5500)
    })
  })
}
