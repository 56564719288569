let start = { x: 0, y: 0 }

export const touchStart = event => {
  start.x = event.touches[0].pageX
  start.y = event.touches[0].pageY
}

export const touchMove = event => {
  return start.y - event.touches[0].pageY
}
