const views = document.querySelectorAll('.view')
import { clickOnView } from './events/click/clickOnView'

export const eventListenersViews = () => {
  views.forEach(view => {
    view.addEventListener('mouseover', () => {
      views.forEach(view => view.classList.add('onView'))
    })
    view.addEventListener('mouseout', () => {
      views.forEach(view => view.classList.remove('onView'))
    })

    view.addEventListener('click', clickOnView)
  })
}
