export default [
  {
    id: 0,
    title: 'Qonto',
    role: 'Developer Full Stack <br/> 1 year 4 months',
    skills: ['Ruby On Rails 7', 'Stimulus JS', 'AWS', 'PostgreSQL', 'Heroku', 'Gitlab'],
    description:
      "As a full stack developer, I joined a team of 5 developers with one lead developer. I worked closely with the marketing, design, and SEO teams to develop and maintain the company's web applications. I utilized my skills in Ruby on Rails, Stimulus, Heroku, GitLab and Prismic to help create and enhance the company's online presence. During the development process, I collaborated with the other developers, designers and SEO team to ensure that the final product was not only technically sound but also visually appealing and SEO friendly."
  },
  {
    id: 1,
    title: 'Octo Technology',
    role: 'Developer Full Stack | 2 years',
    skills: ['React JS', 'JQuery', 'Ruby On Rails', 'Heroku', 'Gitlab', 'PostgreSQL'],
    description:
      "I developed various features to assist different internal teams at Octo. A major front-end redesign of the application was undertaken, transitioning from jQuery to React. I was able to develop the first React pages of Octo's main internal application."
  },
  {
    id: 2,
    title: 'Homeys',
    role: 'Developer Front End <br/> 2 months',
    skills: ['JavaScript', 'Pug', 'Bootstrap', 'Sass'],
    description:
      'Homeys is a suite of solutions for professionals that enables them to activate the three levers of energy bill reduction and comfort control: optimal adjustments of collective heating systems, identification of energy renovation opportunities and choice of the most suitable energy supplier.'
  },
  {
    id: 3,
    title: 'Title 4',
    role: 'Developer Back-End | 2 months',
    skills: ['Ruby On Rails'],
    description: 'Sed commodo porttitor eleifend. Nulla aliquam et elit mattis auctor. Pellentesque efficitur ut est nec elementum.'
  }
]
